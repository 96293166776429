var setCookieWarning = {
    init: function () {
        var consentCookie = Cookies.get('cookieconsent_dismissed');

        var dismissed = function(){
            document.body.classList.add('cookie-dismissed');
        }

        if (consentCookie == 'yes'){
            dismissed();
            return;
        } 

        document.getElementsByClassName('js-cookie-dismiss')[0].addEventListener('click', function(e) {
            e.preventDefault();
            Cookies.set('cookieconsent_dismissed', 'yes', { expires: 365 });
            dismissed();
        });
    }
};