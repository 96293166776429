var setNewsletterSignup = function() {
  var form = document.querySelectorAll('.js-newsletter-signup-form')[0];

  if (form) {
    form.addEventListener('submit', function(e) {
      e.preventDefault();

      var request = new XMLHttpRequest();
      request.responseType = 'json';
      request.open("POST", form.getAttribute('data-update-url'));

      request.onload = function() {
        if (request.status == 200) {
        
          document.querySelectorAll('.js-newsletter-form-thanks')[0].classList.remove('hidden');
          document.querySelectorAll('.js-newsletter-form-result')[0].classList.add('hidden');

          window.dataLayer.push({
            "event": "Newsletter Subscription",
          });
        } 
        else {
          formResultsThanks.innerHTML = "<p>Server not responding please try again later</p>";
        }
      };

      request.send(new FormData(e.target));
    });

    document.getElementById('newsletter-submit-close').addEventListener('click', function(e) {
      e.preventDefault();

      document.getElementsByClassName('js-newsletter-signup-container')[0].style.display = 'none';
    });

  }
}
